@tailwind base;
@tailwind components;
@tailwind utilities;

.select-blue button,
.select-blue input {
  border-color: theme('colors.blue.300');
}

.select-comparison button,
.select-comparison input {
  border-color: theme('colors.comparison.300');
}

.hover-container:hover .hover-element {
  visibility: visible;
}

.hover-container:hover .element-to-be-replacing-on-hover {
  display: block;
}

.hover-container:hover .element-to-be-replaced-on-hover {
  display: none;
}
